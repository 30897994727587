<template>
  <ListMobile v-if="isMobile()" />
  <ListDesktop v-else />
</template>

<script>
import { isMobile } from "@/libs/helpers";
export default {
  name: "Index",
  components: {
    ListDesktop: () => import("@/components/goods/ListDesktop.vue"),
    ListMobile: () => import("@/components/goods/ListMobile.vue"),
  },
  methods: {
    isMobile,
  },
};
</script>

<style scoped></style>
